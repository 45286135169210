<template>
  <div class="about-container">
    <!-- Hero -->
    <hero :content="hero" />

    <!-- Section Info -->
    <section class="container-sm my-4">
      <!-- Tentang Kami -->
      <h2 class="title">
        Tentang Kami
      </h2>
      <blockquote
        class="blockquote pl-1 my-2 border-left-primary border-left-3"
      >
        <p class="mb-0 font-italic first-line-indent">
          Kami hadir untuk para pencari keterampilan digital yang ingin menyiapkan diri menghadapi dunia teknologi yang terus berkembang. Lewat platform pelatihan digital yang dirancang untuk membekali Anda dengan keterampilan yang dibutuhkan di dunia teknologi yang dinamis. Tekov adalah mitra belajar Anda, menyediakan akses ke materi pelatihan berkualitas tinggi yang dirancang untuk mendorong pertumbuhan dan pengembangan karir Anda.
        </p>
        <p class="mb-0 font-italic first-line-indent mt-2">
          Kami berkomitmen untuk memberikan pengalaman belajar yang inovatif dan efektif, memastikan Anda siap menghadapi tantangan di industri teknologi digital. Tekov hadir dengan beragam kursus yang disusun oleh para ahli industri, menawarkan pembelajaran yang interaktif dan praktis.
        </p>
        <p class="mb-0 font-italic first-line-indent mt-2">
          Sejarah kami dimulai dari keinginan untuk menjembatani kesenjangan antara pendidikan dan dunia kerja. Dengan semangat ini, Tekov terus berinovasi untuk menciptakan program pelatihan yang relevan dengan kebutuhan industri saat ini.
        </p>
        <p class="mb-0 font-italic first-line-indent mt-2">
          Tekov bukan sekadar platform pelatihan, tetapi juga komunitas pembelajar dan profesional yang saling mendukung. Kami percaya bahwa dengan memberikan akses ke pengetahuan dan keterampilan yang tepat, kami dapat membantu Anda mencapai karir impian Anda di dunia teknologi digital.
        </p>
        <p class="mb-0 font-italic first-line-indent mt-2">
          Kami di Tekov bertekad untuk meninggalkan dampak positif pada setiap langkah kami, membantu menciptakan masa depan yang lebih cerah dan berkelanjutan bagi semua.
        </p>
      </blockquote>

      <!-- Vision -->
      <h2 class="title">
        Visi Tekov
      </h2>
      <ul class="card p-2 list-unstyled bg-transparent ">
        <li
          v-for="i in 1"
          :key="i"
          class="d-flex list-padding"
        >
          <div>
            <img
              src="@/assets/tekov/icons/check-icon.svg"
              alt=""
              srcset=""
            >
          </div>
          <span class="ml-1">
            Menjadi platform pelatihan online terdepan yang memberdayakan individu untuk sukses di dunia teknologi digital.
          </span>
        </li>
      </ul>

      <!-- Mission -->
      <h2 class="title">
        Misi Tekov
      </h2>
      <b-card class="card p-2 bg-transparent ">
        <table>
          <tr>
            <td class="number-cell">
              1.
            </td>
            <td class="content-cell">
              <strong>Menyediakan Materi Pelatihan Berkualitas Tinggi</strong>
              <p>
                Kami menghadirkan kurikulum yang dirancang oleh para ahli industri dan profesional berpengalaman untuk memastikan peserta mendapatkan pengetahuan dan keterampilan terbaru yang relevan dengan kebutuhan pasar kerja teknologi digital.
              </p>
            </td>
          </tr>
          <tr>
            <td class="number-cell">
              2.
            </td>
            <td class="content-cell">
              <strong>Menciptakan Pengalaman Belajar yang Interaktif dan Efektif</strong>
              <p>
                Kami fokus pada metode pembelajaran yang inovatif dan menarik, termasuk sesi langsung, studi kasus, dan simulasi praktis untuk membantu peserta memahami konsep secara mendalam dan menerapkannya dalam situasi nyata.
              </p>
            </td>
          </tr>
          <tr>
            <td class="number-cell">
              3.
            </td>
            <td class="content-cell">
              <strong>Mendorong Pertumbuhan dan Pengembangan Karir Peserta</strong>
              <p>
                Kami berkomitmen untuk mendukung peserta dalam merencanakan dan mengembangkan jalur karir mereka dengan memberikan bimbingan karir, peluang jaringan, dan akses ke sumber daya industri yang berharga.
              </p>
            </td>
          </tr>
          <tr>
            <td class="number-cell">
              4.
            </td>
            <td class="content-cell">
              <strong>Menjadi Jembatan antara Pendidikan dan Dunia Kerja</strong>
              <p>
                Kami berusaha untuk menjembatani gap antara pendidikan dan dunia kerja dengan menyediakan pelatihan yang sesuai dengan kebutuhan industri dan membantu peserta untuk memulai karir mereka di sektor teknologi digital.
              </p>
            </td>
          </tr>
          <tr>
            <td class="number-cell">
              5.
            </td>
            <td class="content-cell">
              <strong>Fostering a Community of Learners and Professionals</strong>
              <p>
                Kami membangun komunitas yang mendukung antara peserta, alumni, dan profesional di industri teknologi digital untuk berbagi pengetahuan, peluang, dan inspirasi.
              </p>
            </td>
          </tr>
        </table>
      </b-card>
    </section>

    <!-- Our Teams -->
    <section class="container-sm pt-4 pb-2 bg-overlay">
      <div class="mx-2">
        <span class="text-primary">Tim Kami</span>
        <h2 class="title py-1">
          Beberapa orang yang bekerja di Tekov
        </h2>

        <b-row
          class="mt-2"
        >
          <b-col
            v-for="(trainer, i) in trainers"
            :key="i"
            class="mb-4"
            cols="6"
            sm="4"
            md="3"
          >
            <b-avatar
              size="lg"
              :src="trainer.avatar"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
            >
              <feather-icon
                v-if="!trainer.avatar"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
            <div class="py-1">
              <h5 class="font-weight-bolder">
                {{ trainer.name }}
              </h5>
              <span class="font-weight-light text-primary">{{ trainer.job }}</span>
              <p class="mb-1 text-muted">
                {{ trainer.jobDesc }}
              </p>
              <!-- <div class="d-flex">
                <i class="icon-small text-muted mr-1 ti ti-brand-twitter" />
                <i class="icon-small text-muted mr-1 ti ti-brand-linkedin" />
                <i class="icon-small text-muted mr-1 ti ti-brand-dribbble" />
              </div> -->
            </div>
          </b-col>
        </b-row>
      </div>
    </section>

    <!-- Explore -->
    <explore />

    <!-- Footer -->
    <custom-footer />
  </div>
</template>

<script>
import {
  BAvatar, BRow, BCol, BCard,
} from 'bootstrap-vue'

// Custom Components
import Explore from '@/views/tekov/landingpage/components/Explore.vue'
import CustomFooter from '@/views/tekov/landingpage/components/Footer.vue'
import Hero from '@/views/tekov/components/Hero.vue'

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BCard,
    Hero,
    Explore,
    CustomFooter,
  },
  data() {
    return {
      hero: {
        title: 'Tentang Tekov',
        desc:
          'Tekov memiliki nilai-nilai dan tujuan yang dituangkan ke dalam visi & misi perusahaan.',
      },
      trainers: [
        {
          name: 'Fajar Indra P',
          job: 'Trainer Desain Bahan Ajar',
          jobDesc: '',
          avatar: require('@/views/tekov/landingpage/assets/img/trainers/BOSE.png'),
        },
        {
          name: 'Seva Ega R',
          job: 'Trainer 3D',
          jobDesc: '',
          avatar: require('@/views/tekov/landingpage/assets/img/trainers/SEVA.png'),
        },
        {
          name: 'Guntur Bayu P',
          job: 'Trainer Augmanted Reality',
          jobDesc: '',
          avatar: require('@/views/tekov/landingpage/assets/img/trainers/TORO.png'),
        },
        {
          name: 'Muhammad Taufik',
          job: 'Trainer Game edukasi',
          jobDesc: '',
          avatar: require('@/views/tekov/landingpage/assets/img/trainers/TAUFIK.png'),
        },
        {
          name: 'Nala Rusydal Khakim',
          job: 'Trainer Backend',
          jobDesc: '',
          avatar: require('@/views/tekov/landingpage/assets/img/trainers/HAKIM.png'),
        },
        {
          name: 'Luthfi Arya P',
          job: 'Trainer Fullstack',
          jobDesc: '',
          avatar: require('@/views/tekov/landingpage/assets/img/trainers/LUTFI.png'),
        },
        {
          name: 'Bondan Raharjo',
          job: 'Trainer Frontend',
          jobDesc: '',
          avatar: require('@/views/tekov/landingpage/assets/img/trainers/BONDAN.png'),
        },
        {
          name: 'Defasta Abid P',
          job: 'Trainer Android',
          jobDesc: '',
          avatar: require('@/views/tekov/landingpage/assets/img/trainers/ABID.png'),
        },
      ],
    }
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.about-container {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 100px !important;
}

.bg-overlay {
  background: rgba(249, 250, 251, 1);
}

.dark-layout .bg-overlay {
  background: rgb(28, 28, 28);
}

.list-padding {
  padding: 0.3rem 0;
}

.icon-small {
  font-size: 1.3rem;
}

.first-line-indent {
  text-indent: 2em; /* Adjust the value to your preference */
}

.number-cell {
            padding-right: 10px; /* Add some space between the number and the text */
            vertical-align: top; /* Align the number with the top of the paragraph */
        }
        .content-cell {
            text-align: justify; /* Justify the text in the content cell */
        }
</style>
