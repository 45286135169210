<template>
  <section class="hero text-white">
    <div class="hero-body">
      <div class="container-sm">
        <b-row class="py-3">
          <b-col>
            <h1 class="title text-white">
              {{ content.title }}
            </h1>
            <p>
              {{ content.desc }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BRow, BCol, BButton, BBreadcrumb,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    content: {
      type: Object,
      default: () => ({
        title: 'Promo dan Diskon',
        desc:
          'Dapatkan diskon hingga 70% berlangganan Tekov dan tambahan diskon hingga Rp500.000, serta GRATIS hadiah menarik lainnya. Yuk, cek detail promonya!',
      }),
    },
  },
}
</script>

<style lang="scss">
.hero {
  background: linear-gradient(45deg, #444ce7 35.94%, #7f56d9 100%);
}

.breadcrumb-white li {
  a {
    color: white !important;
  }
  color: white !important;
}
</style>
